<template>
  <sd-container full>
    <tooltip-demo/>
  </sd-container>
</template>

<script>
import TooltipDemo from '@/components/TooltipDemo'
export default {
  components: {
    TooltipDemo
  }
}
</script>

<style lang="scss" scoped>
.demo__tooltip{
  padding:32px;
}
</style>
